svg.MuiCircularProgress-circleDeterminate{
    width: 100px;
    color: yellow;
}

/* Pagination style */
.pagination {
    padding: 0;
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    list-style: none;
    outline: none;
}
.MuiAlert-standardSuccess{
    background-color: rgb(0,133,0);
}
.pagination>li {
    margin-bottom: 18px;
}

.pagination>li>a {
    border: none;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 8px 13px;
    outline: none;
    cursor: pointer;
    margin: 5px;
    color: black
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus {
    background-color: rgb(165, 165, 165);
    border-color: none;
    outline: none;
}

/* Ends here */
.fas {
    border: none;
}

.fa-sort {
    cursor: pointer;
    margin: 0.4px;
}

.fa-toggle-off {
    cursor: pointer;
    color: rgb(255, 0, 0);
}

.fa-toggle-on {
    cursor: pointer;
    color: rgb(0, 133, 0);
}

.toggle {
    cursor: pointer;
    color: rgb(0, 133, 0);
}

.search-query {
    box-shadow: var(--search-shadow);
}

.card {
    background: none;
    border: none;
}

.form-control,
select {
    border-radius: 10px;
    height: 45px;
    width: 100%;
}

.form-control:focus,
select:focus {
    outline: none;
    box-shadow: none;
    border-color: black;
}

.btn-outline-primary {
    outline: none;
    box-shadow: none;
    border-color: black;
    background: rgb(253, 253, 253);
    color: var(--btn-black);
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    border: 2px solid black;
}

.btn-outline-primary:hover {
    color: var(--btn-black);
    outline: none;
    box-shadow: none;
    border-color: black;
    background: rgb(250, 249, 249);
    transform: scale(1.02);
    transition-timing-function: ease-in-out;
    transition: 1s;
}

.table {
    box-shadow: var(--shadow);
    border-radius: 10px;
    border-collapse: collapse;
    width: 100%;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
    position: relative;
}

.table th,
.table td {
    text-align: center;
}

.table th {
    color: rgb(0, 0, 0);
    background-color: var(--table-color);
    height: 5px;
    margin: 10px;
}


table tr:nth-child(even) {
    background-color: #f3f3f3f3;
}

table tr.active-row {
    font-weight: bold;
    background-color: #c02222;
}

table tr:hover {
    border: 1px solid;
    padding: 10px;
    /* box-shadow: 5px 5px 5px rgb(172, 71, 25); */
    box-shadow: 5px 5px 5px rgb(230, 225, 225);
    transform: scale(1.05);
}


/* Media Query */
@media (max-width: 730px) {
    .pagination {
        /* margin: 15px auto; */
        flex-direction: row;
    }

    table {
        margin: 0;
        margin-top: 5px;
    }

    .table thead {
        display: none;
    }

    .table tbody,
    tr,
    td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td:nth-of-type(1)::after{
        content: '';
        cursor: pointer;
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 3px;
        font-size: 12px;
        text-align: left;
    }

    .table td::after {
        font-family: "Font Awesome 5 free";
        content: '\f0dc';
        font-weight: 900;
        cursor: pointer;
        position: absolute;
        margin-top: 5px;
        left: 0;
        width: 50%;
        padding-left: 3px;
        font-size: 12px;
        text-align: left;
    }
   
    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }
}

@media (max-width: 665px) {
    table {
        margin: 0;
        margin-top: 5px;
    }

    .table thead {
        display: none;
    }

    .table tbody,
    tr,
    td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }
}

@media (max-width: 552px) {
    table {
        margin: 0;
    }

    .table thead {
        display: none;
    }

    .table tbody,
    tr,
    td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }
}

@media (max-width: 360px) {
    table {
        margin: 0;
    }

    .table thead {
        display: none;
    }

    .table tbody,
    tr,
    td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }
}